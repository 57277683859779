import React from 'react';

const IconLogo = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400" {...props}>
  <path
    d="M191.5 16.24l159.58 92.13v184.26L191.5 384.76 31.92 292.63V108.37L191.5 16.24m0-9.24L23.92 103.75v193.5L191.5 394l167.58-96.75v-193.5L191.5 7z"
    fill="#64ffda"
  />
  <text
    transform="translate(91 276.39)"
    fontSize={271.74}
    fontFamily="Calibri"
    fill="#64ffda"
  >
    {"A"}
  </text>
</svg>
);

export default IconLogo;
